import React from "react"

import Header from "./header"
import Footer from "./footer"

import "../../vendor/dzsparallaxer/dzsparallaxer.scss"

import "../../scss/theme.scss"

import { library } from "@fortawesome/fontawesome-svg-core"
import { fab } from "@fortawesome/free-brands-svg-icons"
import { faPlayCircle } from "@fortawesome/free-regular-svg-icons"
import {
  faAngleRight,
  faQuoteLeft,
  faArrowRight,
  faRss,
  faVideo,
} from "@fortawesome/free-solid-svg-icons"

library.add(
  fab,
  faAngleRight,
  faQuoteLeft,
  faArrowRight,
  faPlayCircle,
  faRss,
  faVideo
)

export default function Layout({ children }) {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  )
}

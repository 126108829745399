import React from "react"
import { Link } from "gatsby"
import { Helmet } from "react-helmet"
import { slide as Menu } from "react-burger-menu"

export default function Header() {
  return (
    <>
      <Helmet htmlAttributes={{ lang: "en" }}>
        <link rel="icon" href="/favicon.png" />
        <meta
          property="og:title"
          content="Rajiv Satyal: Comedian • Host • Speaker"
        />
        <meta property="og:image" content={"/rajiv.png"} />
        <meta
          name="description"
          content="Rajiv Satyal: Comedian • Host • Speaker"
        />
      </Helmet>

      <header
        id="header"
        className="header fixed-top"
        style={{ position: "fixed" }}
      >
        <Menu>
          <Link to="/bio" className="nav-link">
            Bio
          </Link>
          <Link to="/videos" className="nav-link">
            Videos
          </Link>
          <Link to="/press" className="nav-link">
            Press
          </Link>
          <a
            className="nav-link"
            href="https://drybarcomedy.com/rajivs"
            target="_blank"
            rel="noopener noreferrer"
          >
            Dry Bar Special
          </a>
          <a
            className="nav-link"
            href="https://www.eventbrite.com/e/rajiv-satyal-standup-comedy-special-screening-new-york-tickets-852603499017?aff=oddtdtcreator"
            target="_blank"
            rel="noopener noreferrer"
          >
            Rajiv's NY Screening
          </a>
        </Menu>
        <div className="header-section">
          <div id="logoAndNav" className="container">
            <nav className="js-mega-menu navbar navbar-expand-lg">
              <div className="navbar-nav-wrap">
                <Link className="navbar-brand navbar-nav-wrap-brand" to="/">
                  Rajiv Satyal
                </Link>
                <div className="navbar-nav-wrap-content">
                  <Link
                    className="btn btn-sm btn-primary transition-3d-hover"
                    to="/hire"
                    style={{ marginLeft: "10px" }}
                  >
                    Hire Rajiv
                  </Link>
                </div>
                <div
                  id="navBar"
                  className="collapse navbar-collapse navbar-nav-wrap-collapse"
                >
                  <ul className="navbar-nav">
                    <li className="navbar-nav-item">
                      <Link to="/bio" className="nav-link">
                        Bio
                      </Link>
                    </li>
                    <li className="navbar-nav-item">
                      <Link to="/videos" className="nav-link">
                        Videos
                      </Link>
                    </li>
                    <li className="navbar-nav-item">
                      <Link to="/press" className="nav-link">
                        Press
                      </Link>
                    </li>
                    <li>
                      <a
                        className="nav-link"
                        href="https://drybarcomedy.com/rajivs"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Dry Bar Special
                      </a>
                    </li>
                    <li>
                      <a
                        className="nav-link"
                        href="https://www.eventbrite.com/e/rajiv-satyal-standup-comedy-special-screening-new-york-tickets-852603499017?aff=oddtdtcreator"
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        Rajiv's NY Screening
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </header>
    </>
  )
}

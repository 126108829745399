import React from "react"
import { Link } from "gatsby"

export default function CTA() {
  return (
    <>
      <div className="container">
        <div className="w-lg-100 mx-lg-auto pt-5">
          <div
            className="card bg-primary text-white overflow-hidden p-4"
            style={{
              backgroundImage:
                "linear-gradient(150deg, #2d1582 0%, #19a0ff 100%)",
              backgroundRepeat: "repeat-x",
            }}
          >
            <div className="row justify-content-md-start align-items-md-center text-center text-md-left">
              <div className="col-md-9 mb-3 mb-md-0">
                <h2 className="text-white">Show me the funny.</h2>
              </div>
              <div className="col-md-3 text-md-right">
                <Link className="btn btn-light transition-3d-hover" to="/hire">
                  Hire Rajiv
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

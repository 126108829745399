import { useStaticQuery, graphql } from "gatsby"

export const useMetadata = () => {
  const { sanityMetadata } = useStaticQuery(
    graphql`
      query MetadataQuery {
        sanityMetadata {
          id
          social_links {
            name
            link
            icon
          }
          instagram {
            caption
            url
            image {
              asset {
                fluid(maxWidth: 300) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          credits {
            name
            picture {
              asset {
                fluid(maxWidth: 150) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          clients {
            name
            picture {
              asset {
                fluid(maxWidth: 300) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
          testimonial {
            name
            quote
            picture {
              asset {
                fluid(maxWidth: 200) {
                  ...GatsbySanityImageFluid
                }
              }
            }
          }
        }
      }
    `
  )
  return sanityMetadata
}

import React from "react"
import CTA from "./cta"
import { useMetadata } from "../../hooks/usemetadata"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

export default function Footer() {
  const metadata = useMetadata()
  return (
    <footer style={{ backgroundColor: "#1e2022" }} className="mt-5">
      <CTA />
      <div className="container space-2">
        <div className="row">
          <div className="col-6 col-md-6 mb-5 mb-lg-0">
            <h5 className="text-white font-weight-bold">Contact</h5>
            <ul className="nav nav-sm nav-x-0 nav-white flex-column">
              <li className="nav-item">
                <a className="nav-link" href="mailto:rajiv@funnyindian.com">
                  rajiv@funnyindian.com
                </a>
              </li>
            </ul>
          </div>

          <div className="col-md-6">
            <form
              className="js-validate mb-2"
              action="https://funnyindian.substack.com/api/v1/free?nojs=true"
              method="post"
            >
              <h5 className="text-white font-weight-bold mb-3">Subscribe</h5>
              <div className="form-row">
                <div className="col">
                  <div className="js-form-message">
                    <label className="sr-only" htmlFor="subscribeSrEmail">
                      Email address
                    </label>
                    <div className="input-group">
                      <input
                        type="hidden"
                        name="first_url"
                        value="https://funnyindian.substack.com/"
                      />
                      <input type="hidden" name="first_referrer" />
                      <input
                        type="hidden"
                        name="current_url"
                        value="https://rajivsatyal.com/"
                      />
                      <input type="hidden" name="current_referrer" />
                      <input type="hidden" name="referral_code" />
                      <input type="hidden" name="source" value="embed" />
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        id="subscribeSrEmail"
                        placeholder="Email address"
                        aria-label="Email address"
                        required
                        data-msg="Please enter a valid email address."
                      />
                    </div>
                  </div>
                </div>

                <div className="col-auto">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    aria-label="Submit"
                  >
                    <FontAwesomeIcon icon={["fas", "arrow-right"]} />
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="container-fluid space-1 bg-white">
        <div className="text-center">
          <ul className="list-inline mb-0">
            {metadata.social_links.map((item, key) => {
              let library = item.icon.split(" ")[0]
              let icon = item.icon.split(" ")[1].split("fa-")[1]
              return (
                <li className="list-inline-item mb-2 mb-sm-0" key={key}>
                  <a
                    className="btn btn-icon btn-sm btn-ghost-secondary rounded-circle"
                    href={item.link}
                    rel="noopener noreferrer"
                    target="_blank"
                    aria-label={item.name}
                  >
                    <FontAwesomeIcon icon={[library, icon]} />
                  </a>
                </li>
              )
            })}
          </ul>
          <p className="font-size-1 mt-2">
            © FunnyIndian, Inc. 2020. All rights reserved.
          </p>
        </div>
      </div>
    </footer>
  )
}
